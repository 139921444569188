
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bonheur+Royale&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Blaka&family=Bonheur+Royale&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni@1&display=swap');


* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'medium';
}


h1 {
    font-size: 40px;
    color: rgb(20, 20, 19);
    text-shadow: 0 0 20px rgb(0, 0, 0), 0 0 30px #d4af37, 0 0 40px #d4af37, 0 0 50px #d4af37, 0 0 60px #d4af37, 0 0 70px #d4af37, 0 0 80px #d4af37;
    font-weight: bold
    
  }


  h2 {
    font-size: 20px;
    color: gold;
 
    font-weight: bold;
    
  }

  h3 {
    font-size: 20px;
    color: rgb(17, 17, 16);
  text-shadow: 0 0 20px rgb(0, 0, 0), 0 0 30px #d4af37, 0 0 40px #d4af37, 0 0 50px #d4af37, 0 0 60px #d4af37, 0 0 70px #d4af37, 0 0 80px #d4af37;
  }
  
  p {
    color: rgb(255, 253, 241);

    font-size: 14px;

  }

  h4 {
    font-size: 18px;
    color: rgb(6, 6, 6);
    
   
  }

  h5 {
    font-size: 23px;
    color: gold;
    
    
  }
 

  @font-face {
    font-family: "Light";   /*Can be any text*/
    src: local("RoyalKing-Free"),
      url("./fonts/RoyalKing-Free.ttf") format("truetype");
  }
  @font-face {
    font-family: "medium";   /*Can be any text*/
    src: local("Helvetica"),
      url("./fonts/Helvetica.ttf") format("truetype");
  }
  